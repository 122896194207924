import React, { useState, useRef, useEffect } from 'react';
import {
  DesktopHeader,
  HeaderComponent,
  HeaderInner,
  HeaderSections,
  MobileHeader,
  MenuItem,
  StoreName,
  MobileDropownContainer,
  MobileHeaderDrawer,
  HeaderDrawerInner,
  HeaderSection,
  HeaderExpandable,
  HeaderIcon,
  HeaderExpandedList,
  HeaderSectionList,
  HeaderSectionListItem,
  HeaderExpandedListItem,
  DesktopHeaderDropdown,
  DesktopDropdownItemList,
  DesktopDropdownItem,
  CartTotalContainer,
  ShopingIconWrap,
} from './index.styles';
import { Arrow } from '@/assets/svgExports/Arrow';
import { HeaderArrow } from '@/assets/svgExports/HeaderArrow';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import { OrderIcon } from '@/assets/svgExports/OrderIcon';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import { SupermarketIcon } from '@/assets/svgExports/SupermarketIcon';
import { UserIconDark } from '@/assets/svgExports/UserIconDark';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { commonToggleAuthModal } from '@/utils/commonToggleAuthModal';
import { getStoreType } from '@/utils/getStoreType';
import { removeEmoticons } from '@/utils/emoticonsStatus';
import ReactHamburger from '@/components/Common/ReactHamburger';
import {
  DesktopWhatsAppComp,
  MobileWhatsAppComp,
} from '@/components/Common/WhatsAppIconComp';
import getOrdersDropdownList from '@/utils/orderDropdownList';
import LogoutModal from '@/components/LogoutModal';
import { commonOutsideClick } from '@/utils/isClickOutside';
import { ORDERS_DROPDOWN_LIST, STORE_CUSTOMIZATION } from '@/utils/constants';
import { deviceWidth } from '@/utils/deviceWidth';
import { executeLogout } from '@/utils/excuteLogout';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { toggleRfqFormModal } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';
import MegaMenuDesktopView from '@/components/MegaMenu/MegaMenuDesktopView';
import MegaMenuMobileView from '@/components/MegaMenu/MegaMenuMobileView';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import { redirectToHomePage } from '@/utils/handleRedirectToHomePage';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import { showMyAccount } from '@/utils/getNavList';

const Header = ({
  primaryColor = '#6E27FF',
  secondaryColor = '#ffffff',
  showStoreNameOnMobile,
}) => {
  const router = useRouter();
  const ref = useRef();
  const dispatch = useDispatch();

  const [height, setHeight] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const headerRef = useRef(null);
  const [openCategory, setOpenCategory] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [shopCategory, setShopCategory] = useState([]);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showDesktopDropdown, setShowDesktopDropdown] = useState(false);
  const [isHeaderRef, setHeaderRef] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const { store } = useSSRSelector((state) => state.storeReducer);
  const { store_info, services } = store;
  const storeLogo = getActualOrDsStoreLogo(store);
  const theme = store.theme;
  const themeColor = theme?.colors?.primary_color;

  const {
    user,
    items,
    catalog: { categories, productTags },
  } = useSelector((state) => ({
    store: state.storeReducer.store,
    user: state.userReducer,
    items: state.cartReducer.items,
    catalog: state.catalogReducer,
  }));

  const ordersDropdownList = getOrdersDropdownList();
  const { visitorLoginFeatureStatus } = useFeatureLocks();
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;
  // useSaveHeaderHeight(headerRef);

  useEffect(() => {
    setOpen(false);
    setShowLogoutConfirmation(false);
  }, [user?.isLogin]);

  useEffect(() => {
    if (!isHeaderRef && headerRef.current) {
      setHeight(headerRef.current.offsetHeight);
      setHeaderRef(true);
    }
    if (categories && categories.length > 0) {
      const categoryTemp = categories.filter((el) => el.id !== 0);
      setShopCategory([...productTags, ...categoryTemp]);
    }
  }, [categories]);

  function clickExpandable() {
    setOpenCategory(!openCategory);
  }

  const handleScroll = () => {
    if (headerRef.current) {
      setScrolled(headerRef.current.getBoundingClientRect().top <= 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(), { passive: true });

    return () => {
      window.removeEventListener('scroll', () => handleScroll(), {
        passive: true,
      });
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const toggleAuthModal = () => {
    if (
      commonToggleAuthModal({
        userLogin: user?.isLogin,
        storeInfoDomain: store_info?.domain,
      })
    )
      toggleLogoutModal();
  };

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function toggleShowDesktopDropdown() {
    setShowDesktopDropdown(!showDesktopDropdown);
  }

  function onHandleOrdersClick(href) {
    if (user?.isLogin && href === PATH.ORDERS && deviceWidth > 766) {
      toggleShowDesktopDropdown();
    } else {
      router.push(getRoute(PATH.ORDERS, store_info?.domain));
    }
  }
  function toggleShowDesktopDropdown() {
    setShowDesktopDropdown(!showDesktopDropdown);
  }
  const onHandleOrdersDropdown = (item, e = null) => {
    switch (item.action) {
      case ORDERS_DROPDOWN_LIST.MY_ORDERS: {
        toggleShowDesktopDropdown();
        router.push(getRoute(PATH.ORDERS, store_info?.domain));
        break;
      }
      case ORDERS_DROPDOWN_LIST.LOGOUT: {
        toggleLogoutModal();
        break;
      }
      case ORDERS_DROPDOWN_LIST.DEFAULT_PHONE: {
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClickOutside = (e) => {
    const callback = () => setShowDesktopDropdown(false);
    commonOutsideClick({ event: e, pathNo: 0, ref, callback });
  };

  const handleCartClick = () => {
    if (getStoreType({ services }) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    router.push(getRoute(PATH.CART, store_info?.domain));
  };

  function renderUserIconDesktop() {
    return (
      <>
        {deviceWidth > 766 && (
          <>
            <div className="header-3 pointer">
              <div className="mt1" onClick={() => onHandleOrdersClick(PATH.ORDERS)}>
                <UserIconDark color="#000000" width="24" height="24" />
              </div>
            </div>
            {user?.isLogin && (
              <div
                className={
                  'header-3-arrow-img ml1 pointer ' +
                  (showDesktopDropdown ? '' : 'rotate-180')
                }
                onClick={toggleShowDesktopDropdown}
              >
                <HeaderArrow color={secondaryColor} width="10" height="10" />
              </div>
            )}
            <div
              ref={ref}
              className={
                'br2 absolute shadow-2 z-5 header-3-orderdropdown-container' +
                (isVisitorLoginVisible ? '' : '-nologin')
              }
            >
              {showDesktopDropdown &&
                ordersDropdownList.map((item) => {
                  return (
                    <>
                      {item.id === 1 && (
                        <div className="header-3-orderdropdown-container-arrow"></div>
                      )}
                      <div
                        key={item.id}
                        className={
                          'header-3-orderdropdown-container-item flex items-center z-5 ' +
                          (item.id === 3 ? 'red ' : '') +
                          (item.id !== 1 && 'pointer')
                        }
                        onClick={(e) => onHandleOrdersDropdown(item, e)}
                      >
                        {item.id === 1 && (
                          <div className="mr3 mt1">
                            <UserIconDark color={'black'} width="33" height="33" />
                          </div>
                        )}
                        {item.name}
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  function renderUserIconMobile() {
    return (
      <>
        <div
          className={
            'header-3-mobile-login-navigation-container ' + (user?.isLogin ? 'red' : null)
          }
          onClick={toggleAuthModal}
        >
          <UserIconDark color="#000000" width="22" height="22" />
          <div className="mobile-translate-logout-text">
            <span>{user?.isLogin ? 'Logout' : 'Login'}</span>
          </div>
        </div>
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  /**
   * Render cartIcon
   * @return {JSX}
   */
  const _renderCartIcon = () => {
    return items.length > 0 ? (
      <div
        className="pl2 pb2 pt3 pr3 br3"
        style={{
          backgroundColor: secondaryColor,
        }}
      >
        <ShopingIconWrap>
          <SupermarketIcon color={themeColor} width="20" height="20" />
          {items && items.length > 0 && (
            <CartTotalContainer backgroundColor={themeColor} fontColor={secondaryColor}>
              {items.length}
            </CartTotalContainer>
          )}
        </ShopingIconWrap>
      </div>
    ) : (
      <article className="pl2 pb2 pt2 pr3">
        <ShopingIconWrap>
          <SupermarketIcon color={secondaryColor} width="20" height="20" />
        </ShopingIconWrap>
      </article>
    );
  };

  return theme ? (
    <HeaderComponent
      primaryColor={themeColor}
      secondaryColor={secondaryColor}
      ref={headerRef}
      scrolled={scrolled}
    >
      <HeaderInner>
        <HeaderSections
          style={{
            flex: '1 1 100%',
          }}
        >
          <MobileHeader
            style={{
              flex: '1 1 100%',
              paddingRight: 20,
              paddingLeft: 10,
            }}
          >
            <div>
              <ReactHamburger open={isOpen} setOpen={setOpen} theme={secondaryColor} />
            </div>
            <StoreName
              style={{
                paddingLeft: 10,
              }}
              showStoreNameOnMobile={showStoreNameOnMobile}
              fontColor={secondaryColor}
              onClick={() => {
                router.push(getRoute(PATH.PRODUCT, store_info?.domain));
              }}
            >
              {store_info?.name}
            </StoreName>
          </MobileHeader>
          <DesktopHeader
            style={{
              flex: '1 1 100%',
              paddingRight: 20,
              maxWidth: '45vw',
            }}
          >
            {storeLogo && getVisibilityForHeader(theme?.store_logo_status) && (
              <img
                className="header-logo-image mr3 pointer"
                src={storeLogo}
                alt="store logo"
                onClick={() => {
                  redirectToHomePage(PATH.PRODUCT, store_info?.domain);
                  setOpenCategory(false);
                }}
              />
            )}
            {getVisibilityForHeader(theme?.store_name_status) && (
              <StoreName
                onClick={() => {
                  redirectToHomePage(PATH.PRODUCT, store_info?.domain);
                  setOpenCategory(false);
                }}
                fontColor={secondaryColor}
              >
                {store_info?.name}
              </StoreName>
            )}
          </DesktopHeader>
        </HeaderSections>
        <HeaderSections>
          <DesktopHeader>
            <MenuItem
              onClick={() => {
                router.push(getRoute(PATH.PRODUCT, store_info?.domain));
                setOpenCategory(false);
              }}
            >
              Home
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                router.push(getRoute(PATH.ORDERS, store_info?.domain));
              }}
            >
              My Orders
            </MenuItem> */}
            {theme?.cta_config?.header_cta ? (
              <MenuItem
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
                onClick={clickExpandable}
              >
                {theme?.cta_config?.header_cta}
                <HeaderIcon display="flex" size={12} iconPointer={openCategory}>
                  <Arrow color={secondaryColor} />
                </HeaderIcon>
                {/* Desktop Dropdown */}
                {!showMegaMenu() && openCategory && (
                  <DesktopHeaderDropdown
                    primaryColor={themeColor}
                    secondaryColor="#ffffff"
                  >
                    <DesktopDropdownItemList>
                      {shopCategory.map((cat) => (
                        <DesktopDropdownItem
                          secondaryColor="#ffffff"
                          key={`${cat.name}-${cat.id}`}
                          onClick={() => {
                            setOpenCategory(false);
                            if (isPLPRequiredSystem(theme?.theme_class)) {
                              router.push(
                                getRoute(
                                  `${PATH.SHOP}?cid=${cat.id}`,
                                  store_info?.domain
                                ),
                                undefined,
                                { shallow: true }
                              );
                            } else {
                              router.push(
                                getRoute(
                                  `${PATH.PRODUCT}?cid=${cat.id}`,
                                  store_info?.domain
                                ),
                                undefined,
                                { shallow: true }
                              );
                            }
                          }}
                        >
                          {removeEmoticons(cat.name)}
                        </DesktopDropdownItem>
                      ))}
                    </DesktopDropdownItemList>
                  </DesktopHeaderDropdown>
                )}
                {/* End Desktop Dropdown */}
              </MenuItem>
            ) : null}{' '}
            <MenuItem
              onClick={() => {
                router.push(getRoute(PATH.ABOUT_US, store_info?.domain));
              }}
            >
              About Us
            </MenuItem>
            <DesktopWhatsAppComp />
          </DesktopHeader>
          <MenuItem
            margin="0 0 0 25px"
            onClick={() => router.push(getRoute(PATH.SEARCH, store_info?.domain))}
          >
            <SearchIcon color={secondaryColor} size="20" />
          </MenuItem>
          {services?.store_flag !== 0 && (
            <MenuItem margin="0 10px 0 25px" onClick={handleCartClick}>
              {_renderCartIcon()}
            </MenuItem>
          )}
          {deviceWidth > 766 && renderUserIconDesktop()}
        </HeaderSections>
      </HeaderInner>
      {showMegaMenu() && deviceWidth > 766 && (
        <MegaMenuDesktopView open={openCategory} onClose={() => setOpenCategory(false)} />
      )}
      {/* Mobile Desktop Dropdown */}
      <MobileHeader>
        {isOpen && (
          <MobileDropownContainer>
            <MobileHeaderDrawer
              paddingTop={height}
              isOpen={isOpen}
              primaryColor={themeColor}
              secondaryColor="#ffffff"
            >
              <HeaderDrawerInner heightLimiter={height}>
                <HeaderSection border={true}>
                  <MenuItem
                    margin="0px"
                    onClick={() => {
                      setOpen(false);
                      router.push(getRoute(PATH.PRODUCT, store_info?.domain));
                    }}
                  >
                    <span style={{ marginRight: 24, marginLeft: 2 }}>
                      <HeaderHome color={secondaryColor} width />
                    </span>
                    Home
                  </MenuItem>
                </HeaderSection>
                {theme?.cta_config?.header_cta ? (
                  <HeaderExpandable heightLimiter={height}>
                    <HeaderSection
                      display="flex"
                      border={false}
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={clickExpandable}
                    >
                      <MenuItem margin="0px">
                        {theme?.cta_config?.header_cta === 'Shop' && (
                          <span style={{ marginRight: 24 }}>
                            <SupermarketIcon
                              color={secondaryColor}
                              width={20}
                              height={18}
                            />
                          </span>
                        )}
                        {theme?.cta_config?.header_cta}
                      </MenuItem>
                      <HeaderIcon size={16} iconPointer={openCategory}>
                        <Arrow color="#ffffff" />
                      </HeaderIcon>
                    </HeaderSection>
                    {showMegaMenu() ? (
                      <div style={{ paddingLeft: '50px' }}>
                        <MegaMenuMobileView
                          open={openCategory}
                          onClose={() => setOpen(false)}
                          primaryColor={themeColor}
                          secondaryColor={secondaryColor}
                        />
                      </div>
                    ) : (
                      openCategory && (
                        <HeaderExpandedList>
                          {shopCategory.map((item) => (
                            <HeaderExpandedListItem
                              key={`${item.name}-${item.id}`}
                              onClick={() => {
                                setOpen(false);
                                if (isPLPRequiredSystem(theme?.theme_class)) {
                                  router.push(
                                    getRoute(
                                      `${PATH.SHOP}?cid=${item.id}`,
                                      store_info?.domain
                                    ),
                                    undefined,
                                    { shallow: true }
                                  );
                                } else {
                                  router.push(
                                    getRoute(
                                      `${PATH.PRODUCT}?cid=${item.id}`,
                                      store_info?.domain
                                    ),
                                    undefined,
                                    { shallow: true }
                                  );
                                }
                              }}
                            >
                              {removeEmoticons(item.name)}
                            </HeaderExpandedListItem>
                          ))}
                        </HeaderExpandedList>
                      )
                    )}
                  </HeaderExpandable>
                ) : null}{' '}
                <HeaderSection border={true}>
                  <HeaderSectionList>
                    <HeaderSectionListItem>
                      <OrderIcon secondaryColor="#ffffff" />
                      <MenuItem
                        secondaryColor="#ffffff"
                        onClick={() => {
                          router.push(getRoute(PATH.ORDERS, store_info?.domain));
                        }}
                      >
                        {getCustomCtaText('my_orders_cta')}
                      </MenuItem>
                    </HeaderSectionListItem>
                    <MobileWhatsAppComp secondaryColor="#ffffff" />
                    <HeaderSectionListItem>
                      <InfoIcon size={24} secondaryColor="#ffffff" />
                      <MenuItem
                        secondaryColor="#ffffff"
                        onClick={() => {
                          router.push(getRoute(PATH.ABOUT_US, store_info?.domain));
                        }}
                      >
                        About Us
                      </MenuItem>
                    </HeaderSectionListItem>
                  </HeaderSectionList>
                </HeaderSection>
                {showMyAccount() ? (
                  <HeaderExpandable heightLimiter={height}>
                    <HeaderSection
                      display="flex"
                      border={false}
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={() => setOpenMore(!openMore)}
                    >
                      <MenuItem margin="0px">More</MenuItem>
                      <HeaderIcon size={16} iconPointer={openMore}>
                        <Arrow color="#ffffff" />
                      </HeaderIcon>
                    </HeaderSection>
                    {openMore && (
                      <HeaderExpandedList>
                        <HeaderExpandedListItem
                          onClick={() =>
                            router.push(getRoute(PATH.MY_ACCOUNT, store_info?.domain))
                          }
                        >
                          My Account
                        </HeaderExpandedListItem>
                      </HeaderExpandedList>
                    )}
                  </HeaderExpandable>
                ) : (
                  <HeaderSection>
                    <HeaderSectionList>
                      {deviceWidth < 767 &&
                        isVisitorLoginVisible &&
                        renderUserIconMobile()}
                    </HeaderSectionList>
                  </HeaderSection>
                )}
              </HeaderDrawerInner>
            </MobileHeaderDrawer>
          </MobileDropownContainer>
        )}
      </MobileHeader>
      {/* End Mobile Desktop Dropdown */}
    </HeaderComponent>
  ) : (
    <></>
  );
};

export default Header;
